const faqData = [
    {
        id: 1,
        imgPath: "/assets/img/1.png",
        question: "Why is the EU putting in place a Carbon Border Adjustment Mechanism?",
        answer: "The EU leads global climate change efforts, targeting a 55% reduction in emissions by 2030 and aiming for climate neutrality by 2050 through the European Green Deal. The Fit for 55 proposals in July 2021 advanced these goals, with key policies now enacted into EU law, including the Carbon Border Adjustment Mechanism (CBAM).",
        category: "Regulation",
      },
      {
        id: 2,
        imgPath: "/assets/img/2.png",
        question: "How does the CBAM work?",
        answer: "The Carbon Border Adjustment Mechanism (CBAM) aligns with EU international commitments and WTO rules, mirroring the EU Emissions Trading System (ETS). It charges importers for the carbon emissions embedded in goods according to EU ETS standards, starting in 2026. Importers must purchase certificates reflecting the EU carbon price. Non-EU producers can offset their CBAM costs if they've paid for carbon emissions in their own countries. CBAM aims to prevent carbon leakage and motivate non-EU producers and countries to adopt greener practices and carbon pricing.",
        category: "Regulation",
      },
      {
        id: 3,
        imgPath: "/assets/img/3.png",
        question: "How does CBAM interact with the EU Emissions Trading System (ETS)?",
        answer: "The EU Emissions Trading System (ETS) is the world's first large-scale carbon market, aiming to reduce greenhouse gas emissions from power production and heavy industry by setting a cap and requiring emissions allowances. To mitigate carbon leakage, industries receive some free allowances, which will decrease over time to enhance the incentive for decarbonization. The Carbon Border Adjustment Mechanism (CBAM) is being introduced as these free allowances are phased out, especially from 2026 onwards, to strengthen the EU's climate objectives. CBAM, which differs slightly from the ETS, requires importers to buy certificates for their goods' embedded emissions without a cap on the number of certificates. The price of CBAM certificates will reflect the ETS market price. Full CBAM implementation in 2026 will adjust in line with the ETS, particularly concerning the reduction of free allowances. Until 2034, when free allowances are fully eliminated, CBAM will only target emissions not already covered by ETS allowances, ensuring fair treatment for EU producers and importers alike.",
        category: "Regulation",
      },
      {
        id: 4,
        imgPath: "/assets/img/4.png",
        question: "How is the CBAM compatible with other ETS systems outside the EU?",
        answer: "The CBAM ensures fair treatment for imported goods compared to EU products through three key features: 1) It considers the actual embedded emissions, rewarding exporters to the EU for reducing their carbon footprint with lower CBAM payments; 2) The price for CBAM certificates is the same as the carbon price under the EU ETS for EU producers; and 3) Any carbon price paid outside the EU for the embedded emissions is deducted to prevent double pricing. Additionally, the Commission will establish secondary legislation to acknowledge effective carbon prices paid in third countries. During the transitional period, importers must report the carbon price from the country of origin, accounting for any reductions or compensations..",
        category: "Regulation",
      },
      {
        id: 5,
        imgPath: "/assets/img/5.png",
        question: "Which sectors does the new mechanism cover and why were they chosen?",
        answer: "The CBAM initially applies to imports of goods in the following sectors: • Cement • Iron and Steel • Aluminium • Fertilisers • Hydrogen • Electricity. These sectors were selected following specific criteria, in particular their high risk of carbon leakage and high emission intensity which will eventually – once fully phased in –represent more than 50% of the emissions of the industry sectors covered by the ETS. In the future, the CBAM may be extended to other ETS sectors.",
        category: "Regulation",
      },
      {
        id: 5,
        imgPath: "/assets/img/5.png",
        question: "I am unable to separate the product inputs for my installation based on a tariff code / cn number. What should I do?",
        answer: "There are two valid strategies to create a valid emissions report in this scenario: 1. Use total production values. This means that all source streams (materials used in production) are entered and the full production output is also listed. It is important that your inputs follow the same logic, e.g. not using the total production output would result in very high emissions in this scenario. 2. If your input materials are very similar and there are no major differences in energy consumption during the different production processes, you can break down the numbers based on other data, such as production output based on groups of goods produced. For example, let's say that the approximate percentage of the total production of the goods in question is 30%. In this scenario, you can use 30% of the materials used and 30% of the energy consumption..",
        category: "CBAM",
      },
      {
        id: 6,
        imgPath: "/assets/img/5.png",
        question: "Can I use the data generated for other clients who request CBAM data?",
        answer: "Yes, you can use the data generated for other customers. Please make sure that the CN number is the same.",
        category: "CBAM",
      },
      {
        id: 7,
        imgPath: "/assets/img/5.png",
        question: "How often do I have to update the CBAM survey?",
        answer: "CBAM surveys need to be updated quarterly, but since your data is secured & stored, we'll only ask for minor updates & additional information in Q4. The base data for all 2024 surveys will be from 2023, the base data for all 2025 surveys will be 2024. In theory, One report per production year is sufficient.",
        category: "CBAM",
      },
      {
        id: 8,
        imgPath: "/assets/img/5.png",
        question: "I use renewable energy in my production process. Where can I add this information and upload the certificates?",
        answer: "We've decided to make the first CBAM survey as easy as possible for our suppliers. Therefore, we have prioritized compliance over accuracy for this CBAM reporting quarter. As we all become more familiar with the regulation, additional information can be submitted to increase the accuracy of your report. Our collective goal is to have highly accurate data in Q3 or Q4 2025.",
        category: "CBAM",
      },
      {
        id: 9,
        imgPath: "/assets/img/5.png",
        question: "I don't want to share information about my supply chain with my European customer.",
        answer: "The CBAM regulation, among several other active or soon to be relevant regulations, requires a certain level of transparency of your supply chain. Your customer would not be able to produce a compliant CBAM report for the EU Commission without the necessary sub-survey data.",
        category: "CBAM",
      },
      {
        id: 10,
        imgPath: "/assets/img/6.png",
        question: "Why did I receive a request to submit a supplier survey?",
        answer: "Under CBAM regulations, importers of regulated goods are required to request their suppliers to complete a survey detailing carbon emission data. This data is necessary to accurately calculate the carbon footprint associated with the production of those goods. The importer requesting this survey from you needs data on the carbon emissions associated with the goods they purchased from your company. This information is essential for the importer to determine the correct number of carbon certificates they need to purchase to comply with CBAM.",
        category: "Operator"
    },
    {
        id: 11,
        imgPath: "/assets/img/7.png",
        question: "I am just a dealer and don't have production data. What should I do?",
        answer: "The survey process accounts for situations where you might be a dealer without direct production data. In these cases, you are required to forward the survey request to your supplier(s) from whom you purchased the goods covered by the survey. Once your supplier completes the forwarded survey (referred to as a Sub-Survey), it will be sent directly to the importer.",
        category: "Operator"
    },
    {
        id: 12,
        imgPath: "/assets/img/8.png",
        question: "What are the steps involved in submitting the supplier survey?",
        answer: "The supplier survey submission process in the CORA Supplier App involves three main steps:\n1. Navigate to the required survey from the Survey Submission landing page.\n2. Fill in the survey data through a series of consecutive screens.\n3. Review the provided data and submit the survey.",
        category: "Operator"
    },
    {
        id: 13,
        imgPath: "/assets/img/9.png",
        question: "Where can I find additional information and support regarding the CBAM and the survey?",
        answer: "The CORA platform offers various resources to assist you in understanding CBAM requirements and completing the survey. These include:\n● Short video tutorials accessible on the survey landing page covering essential aspects of CBAM and the survey process.\n● The CBAM Academy, accessible to all suppliers who received a survey invitation from your European customer. This knowledge base provides comprehensive videos and complete video walkthroughs to educate suppliers on the most relevant concepts of the CBAM regulation.\n● Direct support from Ventum Consulting, covering technical, functional, and regulatory aspects of the CBAM process. This support is available to suppliers.",
        category: "Operator"
    },
    {
      id: 14,
      imgPath: "/assets/img/10.png",
      question: "What are direct emissions?",
      answer: "Direct emissions are greenhouse gases released directly from sources that are owned or controlled by the reporting company. These emissions are generated during the production process of the goods covered by the CBAM regulations.",
      category: "Operator"
  },
  {
      id: 15,
      imgPath: "/assets/img/11.png",
      question: "How do I calculate direct emissions for the CBAM supplier survey?",
      answer: "The CORA Supplier App simplifies the direct emissions calculation process. The app guides you through a step-by-step process to collect the necessary information, and it automatically calculates your direct emissions based on your inputs. Here's a summary of the process:\n1. Identify the Production Route: Select the relevant production route used to manufacture the goods for the given survey.\n2. Detail Emission Sources: List each emission source associated with the chosen production route.\n  ○ This includes precursors (materials), fuels, gases, and other inputs that contribute to emissions during the production process.\n  ○ For each emission source, you will need to provide information such as its CN Code, the quantity used, and whether it was purchased from another supplier.\n3. Quantity and Units: Enter the quantity of each precursor used in the production process. Make sure to specify the correct unit of measurement (tons or kilograms).\n4. Purchased or Self-Produced: Indicate whether each source stream was purchased from another supplier or produced internally.",
      category: "Operator"
  },
  {
      id: 16,
      imgPath: "/assets/img/12.png",
      question: "What if I purchased some of the materials from another supplier?",
      answer: "If you sourced materials from another supplier, you will need to initiate a sub-survey to gather emissions data from them. The CORA app streamlines this process. In the Subsurvey List section, you will enter the contact information for each supplier from whom you purchased materials. Once you send the sub-survey, the suppliers will receive a notification and can provide their emissions data. This information will then be incorporated into the overall emissions calculation for your survey.",
      category: "Operator"
  },
  {
      id: 17,
      imgPath: "/assets/img/13.png",
      question: "What happens if I don't have all the data required for the direct emissions calculation?",
      answer: "The CORA app is designed to help you identify potential data gaps. If any mandatory fields are missing, you will be prompted to provide the necessary information. Additionally, your customer provides supplier support through Ventum Consulting to address any technical, functional, or regulatory questions you might have about the CBAM process.",
      category: "Operator"
  }
];

export default faqData;