import {
  Column,
  Grid,
  Loading,
  Button,
  Modal,
} from '@carbon/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowRight } from '@carbon/react/icons';
import ParameterTable from '../../components/ParameterTable/ParameterTable';
import { SurveyViewData } from '../../types/SurveyViewData';
import './WelcomePage.scss';
import ParameterTableSkeleton from '../../components/ParameterTable/ParameterTableSkeleton';
import { SkeletonText } from '@carbon/react';
import VideoEmbed from '../../components/Vimeo/Vimeo'; 
import greenFactory from '../../assets/img/factory.png';
import { LOCAL_STORAGE_RELOAD_FLAG } from '../../constants/constants';


const WelcomePage = ({ survey, surveyDataLoading }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modalVideoKey, setModalVideoKey] = useState(0); // Add separate state for modal video

  let [searchParams] = useSearchParams();
  let surveyId = searchParams.get('surveyId');
  let testCN = searchParams.get('testCN');
 
  useEffect(() => {
    if (!surveyId && !testCN) {
      navigate(`error`);
    }
  }, [searchParams, navigate]);

  if (surveyId != null && survey == null && surveyDataLoading == false) {
    if (localStorage.getItem(LOCAL_STORAGE_RELOAD_FLAG) === 'true'){
      window.location.reload();
      localStorage.removeItem(LOCAL_STORAGE_RELOAD_FLAG);
    }
  }

  const handleModalClose = () => {
    setOpen(false);
    setModalVideoKey(modalVideoKey + 1); // Change modalVideoKey when modal is closed
  };

  return (
      <>
      <Grid>
        <Column lg={16} md={8} sm={4} className="survey-section">
          <h2>
            {survey ? 
              t('welcome.header', {
                importerName: survey['importer_name'],
              }) :
              <SkeletonText heading paragraph />
              }
          </h2>
        </Column>
      </Grid>

      <Grid>
        {survey && !surveyDataLoading ? <ParameterTable id={surveyId!} data={survey} /> : <> <ParameterTableSkeleton isLoading={true}/> <Loading withOverlay={true} /> </> }

        <Column md={4} lg={12} sm={4} className="landing-page__tab-content">
            <h2 className="landing-page__subheading">{t('surveyInfo.introTextHeader')}</h2>
            <p className="landing-page__p">
           {t('surveyInfo.introText')}
            </p>
            <Button onClick={() => setOpen(true)}>{t('surveyInfo.buttonText')}</Button>
            <Modal open={open} onRequestClose={handleModalClose} passiveModal modalHeading="Introduction to CBAM & CORA" primaryButtonText="Close Window">
              <p style={{ marginBottom: '1rem' }}>
                <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133967499"
                  title="CORA Context Help - Welcome"
                  width="100%"
                  height="500px"
                  key={modalVideoKey.toString()} // Convert modalVideoKey to string
                />
              </p>
            </Modal>
          </Column>
          <Column md={2} lg={4} sm={2}>
            <img className="landing-page__illo"
              src={greenFactory}
              alt="Carbon illustration"
              width={367}
              height={350}
            />
          </Column>

          <Column lg={16} md={8} sm={4} className="shortVideoBox">
<h3>{t('surveyInfo.walkthroughTitle')}</h3> 
<p>{t('surveyInfo.walkthroughSubtitle')}</p>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video11Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/134063397"
                  title="CBAM Academy - Full Survey Walkthrough English" 
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video11Description')}</p>
            </div>
          </div>
        </Column>
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video12Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/134112264"
                  title="CBAM Academy - Full Survey Walkthrough Chinese" 
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video12Description')}</p>
            </div>
          </div>
        </Column>
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video13Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
            src="https://video.ibm.com/embed/recorded/134135440"
            title="CBAM Academy - Full Survey Walkthrough Turkish" 
            width="100%"
            height="auto"
          />
            <div className="video-description">
              <p>{t('surveyInfo.video13Description')}</p>
            </div>
          </div>
        </Column>

        <Column lg={16} md={8} sm={4} className="shortVideoBox">
          <h3>{t('surveyInfo.tutorialsHeader')}</h3> 
          <p>{t('surveyInfo.tutorialsSubHeader')}</p>
        </Column>

        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video1Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133972395"
                  title="CBAM Academy - Reporting Periods for Operators"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video1Description')}</p>
            </div>
          </div>
        </Column>
    
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video2Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133967506"
                  title="CBAM Academy - Calculating Direct Emissions"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video2Description')}</p>
            </div>
          </div>
        </Column>
       
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video3Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133967504"
                  title="CBAM Academy - Calculating Indirect Emissions"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video3Description')}</p>
            </div>
          </div>
        </Column>
       
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video4Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133967502"
                  title="CBAM Academy - Calculating Emissions"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video4Description')}</p>
            </div>
          </div>
        </Column>

      </Grid>
    </>
  );
};

export default WelcomePage;
