import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  ModalBody,
  InlineNotification,
  TextArea,
} from '@carbon/react';
import { CheckmarkFilled, WarningFilled } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import './FormCompletion.scss';

interface FormCompletionProps {
  onClose: () => void;
  comparisonStatus: {
    direct: { status: string; difference: number };
    indirect: { status: string; difference: number };
    activity: { status: string; difference: number };
  };
  onSubmitWithRemarks?: (remarks: string) => void;
}

const FormCompletion: React.FC<FormCompletionProps> = ({ 
  onClose,
  comparisonStatus,
  onSubmitWithRemarks = () => {},
}) => {
  const { t } = useTranslation();
  const [remarks, setRemarks] = useState('');

  const hasEmissionsAboveThreshold = 
    (comparisonStatus.direct.status === 'warning' && comparisonStatus.direct.difference > 20) ||
    (comparisonStatus.indirect.status === 'warning' && comparisonStatus.indirect.difference > 20);

  const hasWarnings = 
    comparisonStatus.direct.status === 'warning' ||
    comparisonStatus.indirect.status === 'warning' ||
    comparisonStatus.activity.status === 'warning';

  // Updated validation: require remarks for both warnings and validation errors
  const isValid = (!hasWarnings && !hasEmissionsAboveThreshold) || 
                 ((hasWarnings || hasEmissionsAboveThreshold) && remarks.trim().length > 0);

  const handleClose = () => {
    onClose();
     window.location.href = '/';
  };

  return (
    <Modal 
    open={true} 
    onClose={onClose}
    modalLabel="CORA"
    modalHeading={hasEmissionsAboveThreshold 
      ? t('survey.submission.validation.error') 
      : t('survey.submission.success.title')}
    primaryButtonText={hasEmissionsAboveThreshold || hasWarnings 
      ? "Submit with Remarks"  // Text for cases with warnings/errors
      : "Submit"               // Text for normal case
    }
    secondaryButtonText="Back to Survey"
    primaryButtonDisabled={!isValid}
    onRequestSubmit={() => {
      if (isValid) {
        onSubmitWithRemarks(remarks);
      }
    }}
    onSecondarySubmit={() => onClose()}
  >
      <ModalBody>
        <div className="flex flex-col items-center justify-center p-4">
          {hasEmissionsAboveThreshold ? (
            <>
              <InlineNotification
                kind="error"
                hideCloseButton
                title={t('survey.submission.validation.errorTitle')}
                subtitle={t('survey.submission.validation.errorSubtitle')}
              />
              <div className="remarks-section mt-4">
                <TextArea
                  labelText={t('survey.submission.modalData.remarkText')}
                  placeholder={t('survey.submission.modalData.remarkPlaceholder')}
                  value={remarks}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRemarks(e.target.value)}
                  invalid={remarks.trim().length === 0}
                  invalidText={t('survey.submission.modalData.remarkInvalid')}
                />
              </div>
            </>
          ) : hasWarnings ? (
            <>
              <InlineNotification
                kind="warning"
                hideCloseButton
                title="Review Required"
                subtitle="There are some deviations in your data that require explanation."
              />
              <div className="warning-details mt-4">
                {/* ... existing warning details ... */}
              </div>
              <div className="remarks-section mt-4">
                <TextArea
                  labelText="Please explain the deviations in your data (required)"
                  placeholder="Enter your explanation here..."
                  value={remarks}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRemarks(e.target.value)}
                  invalid={remarks.trim().length === 0}
                  invalidText="An explanation is required when there are warnings"
                />
              </div>
            </>
          ) : (
            <p className="text-center text-gray-600">
              {t('survey.submission.success.description')}
            </p>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
export default FormCompletion;