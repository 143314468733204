import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Dropdown,
  Form,
  Grid,
  InlineLoading,
  NumberInput,
} from '@carbon/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VideoEmbed from '../../Vimeo/Vimeo';
import { FormStateAttribute } from '../FormMultisteps';
import '../FormMultisteps.scss';
import './FormStep7.scss';

interface DynamicField {
  id: number;
  name: string;
  value: string;
  unit: string;
  mandatory: boolean;
  definition: string;
}

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const FormStep7 = ({ formState, handleChange, prevStep, nextStep }: any) => {
  const { t } = useTranslation();
  const [hasAttemptedNextStep, setHasAttemptedNextStep] = useState(false);
  // Toggle to switch all fields between mandatory and optional
  const alwaysOptional = true; // Change to true to make all fields optional
  const [videoKey, setVideoKey] = useState(0); // Add videoKey state

  const fetchParameters = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/surveys/parameters/${formState.route.id}`, {
        method: 'GET',
        headers: { Accept: 'application/json' },
      });
      const result = await response.json();
      return result.param_list.map(
        (param: {
          id: any;
          name: any;
          unit: any;
          mandatory: any;
          definition: any;
          list_of_values: any;
        }) => ({
          id: param.id,
          name: param.name,
          value: formState.dynamicFields?.find((f: { id: any }) => f.id === param.id)?.value || '',
          unit: param.unit,
          mandatory: alwaysOptional ? false : param.mandatory,
          definition: param.definition,
          list_of_values: param.list_of_values,
        }),
      );
    } catch (error) {
      console.error('Fetching parameters failed', error);
      return [];
    }
  };

  // Function to handle the change of each specific parameter
  const handleSpecificParameterChange = (id: number, value: string) => {
    // Map over the specificParameters to update the value of the parameter with the matching id
    const updatedParameters = formState.specificParameters.map((param: { id: number }) => {
      if (param.id === id) {
        return { ...param, value: value };
      }
      return param;
    });

    // Call the handleChange function with the new state
    handleChange({ name: 'specificParameters', value: updatedParameters });
  };

  const handleParams = (param_list: any) => {
    const specificParameters: FormStateAttribute = {
      name: 'specificParameters',
      value: param_list,
    };
    handleChange(specificParameters);
    // when to trigger?
    // 1. creation
    // 2. update
    // 3. change of its value
  };

  useEffect(() => {
    const updateFormFields = async () => {
      const fieldsFromApi = await fetchParameters();
      handleParams(fieldsFromApi);
    };

    if (!formState.specificParameters) {
      updateFormFields();
    }
  }, [formState.routeId]);

  const validateForm = () => {
    return formState.specificParameters.every(
      (field: { mandatory: any; value: { toString: () => string } }) =>
        field.mandatory ? (field.value?.toString().trim() ?? '') !== '' : true,
    );
  };

  const handleNextStep = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setHasAttemptedNextStep(true);

    if (validateForm()) {
      nextStep(event);
    }
  };

  return (
    <>
      <Form onSubmit={handleNextStep} id="step7" className="form-step">
        <div className="grid-container-element">
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h4>{t('survey.step7.name')}</h4>
              </Column>
              {/* Check if specificEmissions is not yet loaded */}
              {!formState.specificParameters ? (
                <Column lg={16} md={8} sm={4}>
                  <InlineLoading description={t('survey.step7.loadingText')} status="active" />
                </Column>
              ) : (
                // If specificEmissions is loaded, render the fields
                formState.specificParameters?.map(
                  (param: {
                    id: number;
                    list_of_values: string | any[];
                    name: any;
                    mandatory: any;
                    value: string;
                    unit: any;
                  }) => (
                    <Column lg={8} md={4} sm={4} key={param.id}>
                      {param.list_of_values && param.list_of_values.length > 0 ? (
                        <Dropdown
                          id={`dropdown-${param.id}`}
                          titleText={`${param.name}`}
                          helperText={param.mandatory ? '' : ` (optional)`}
                          label={param.name}
                          items={param.list_of_values}
                          selectedItem={param.value}
                          invalidText={`Please enter ${param.name}`}
                          invalid={param.mandatory && hasAttemptedNextStep && !param.value}
                          itemToString={(item: any) => (item ? item : '')}
                          onChange={(event: { selectedItem: string }) =>
                            handleSpecificParameterChange(param.id, event.selectedItem)
                          }
                        />
                      ) : (
                        <NumberInput
                          step={0.01}
                          name={param.name}
                          id={`input-${param.id}`}
                          label={`${param.name} (${param.unit})`}
                          helperText={param.mandatory ? '' : ` (optional)`}
                          disableWheel={true}
                          hideSteppers={true}
                          allowEmpty={true}
                          invalidText={`Please enter ${param.name}`}
                          invalid={param.mandatory && hasAttemptedNextStep && !param.value}
                          value={param.value}
                          onChange={(event: { target: { value: string } }) =>
                            handleSpecificParameterChange(param.id, event.target.value)
                          }
                        />
                      )}
                      {/* TODO: Visualize definition in tooltip*/}
                    </Column>
                  ),
                )
              )}
            </Grid>
          </div>
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h5 className="faq">{t('survey.step7.faq.title')}</h5>
                <Accordion>
                  <AccordionItem title={t('survey.step7.faq.accordion1.title')}>
                    <p>{t('survey.step7.faq.accordion1.text')}</p>
                  </AccordionItem>
                  <AccordionItem title={t('survey.step7.faq.accordion2.title')}>
                    <p>
                      <div>
                        <VideoEmbed
                          src="https://video.ibm.com/embed/recorded/133967498"
                          title="CBAM Academy - Additional Reporting Parameters"
                          width="100%"
                          height="auto"
                          key={videoKey.toString()} // Convert videoKey to string
                        />
                      </div>
                    </p>
                  </AccordionItem>
                  <AccordionItem title={t('survey.step7.faq.accordion3.title')}>
                    <p>{t('survey.step7.faq.accordion3.text')}</p>
                  </AccordionItem>
                </Accordion>
              </Column>
            </Grid>
          </div>
        </div>
        <Grid>
          <Column lg={16} md={8} sm={4}>
            <div className="step-btn-set-container">
              <ButtonSet className="step-btn-set">
                <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                  {t('common.buttons.back')}
                </Button>
                <Button kind="primary" tabIndex={0} type="submit">
                  {t('common.buttons.next')}
                </Button>
              </ButtonSet>
            </div>
          </Column>
        </Grid>
      </Form>
    </>
  );
};

export default FormStep7;
