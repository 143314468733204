import {
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Form,
  Grid,
  InlineNotification,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TextArea,
} from '@carbon/react';
import { CheckmarkFilled, WarningFilled } from '@carbon/react/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileUploader } from '@carbon/react';
import './FormStep9.scss';

interface IEmission {
  category: string;
  emission_name: string;
  activity: string;
  unit: string;
  emission_id: number;
  direct_multiplier: number;
  indirect_multiplier: number;
}

interface IFormState {
  directEmissions: IEmission[];
  calculatedEmissionsDirectPerTonne: number;
  calculatedEmissionsIndirectPerTonne: number;
  calculatedEmissionsTotalPerTonne: number;
  route: { name: string };
  calculationMethod: string;
  CNNumber: string;
}

const FormStep9 = ({
  formState,
  handleChange,
  prevStep,
  handleSubmit: submitForm,
  onFilesUpload,
}: any) => {
  const { t } = useTranslation();
  const [videoKey] = useState(0);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [defaultEmissions, setDefaultEmissions] = useState<any>(null);
  const [comparisonStatus, setComparisonStatus] = useState({
    direct: { status: 'loading', difference: 0 },
    indirect: { status: 'loading', difference: 0 },
    activity: { status: 'loading', difference: 0 },
  });

  const PowerConsumptionComparison = ({ indirectEmission }: { indirectEmission: any }) => {
    // Ensure we have a number by converting the input
    const powerLevel = Number(indirectEmission) || 0;

    const getPowerCategory = (power: number) => {
      if (power >= 100) return 0; // 100+ MW
      if (power >= 10) return 1; // 10-100 MW
      if (power >= 1) return 2; // 1-10 MW
      return 3; // < 1 MW
    };

    const powerCategory = getPowerCategory(powerLevel);

    return (
      <div className="details-section mt-4">
        <div className="comparison-header">
          <h4>Power Consumption Context</h4>
        </div>
        <div className="details-content">
          <div className="power-comparison-item highlight">
            <div className="power-level">
              <span className="label">Your Installation's Power Consumption</span>
              <span className="value">
                {typeof powerLevel === 'number' ? powerLevel.toFixed(1) : '0.0'} MW
              </span>
            </div>
          </div>

          {powerComparisons.map((comparison, index) => (
            <div
              key={comparison.power}
              className={`power-comparison-item ${index === powerCategory ? 'current' : ''}`}
            >
              <div className="power-level">
                <span className="label">Reference Level</span>
                <span className="value">{comparison.power}</span>
              </div>
              <div className="examples">
                <span className="label">Comparable to</span>
                <ul className="example-list">
                  {comparison.examples.map((example, i) => (
                    <li key={i} className="value">
                      {example}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const powerComparisons = [
    {
      power: '100 MW+',
      examples: [
        t('survey.submission.power.example100_1'),
        t('survey.submission.power.example100_2'),
        t('survey.submission.power.example100_3'),
      ],
    },
    {
      power: '10 MW',
      examples: [
        t('survey.submission.power.example10_1'),
        t('survey.submission.power.example10_2'),
        t('survey.submission.power.example10_3'),
      ],
    },
    {
      power: '1 MW',
      examples: [
        t('survey.submission.power.example1_1'),
        t('survey.submission.power.example1_2'),
        t('survey.submission.power.example1_3'),
      ],
    },
    {
      power: '0.1 MW (100 kW)',
      examples: [
        t('survey.submission.power.example0_1'),
        t('survey.submission.power.example0_2'),
        t('survey.submission.power.example0_3'),
      ],
    },
  ];

  const getOrderedCategories = () => {
    const orderedKeys = ['CBAM_PRRP', 'CBAM_PRFG', 'CBAM_PROI'];
    return orderedKeys
      .map((key) => ({ key, ...categories[key] }))
      .filter((category) => category.items.length > 0);
  };

  useEffect(() => {
    const fetchAndCompareEmissions = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/emissions/default/${formState.CNNumber}`);
        const data = await response.json();
        setDefaultEmissions(data);

        if (data) {
          // Calculate total activity
          const totalActivity = formState.directEmissions.reduce(
            (sum: number, emission: IEmission) => sum + Number(emission.activity),
            0,
          );

          // Calculate differences
          const directDiff =
            ((formState.calculatedEmissionsDirectPerTonne - data.direct) / data.direct) * 100;
          const indirectDiff =
            ((formState.calculatedEmissionsIndirectPerTonne - data.indirect) / data.indirect) * 100;
          const activityDiff =
            ((totalActivity - formState.productionCalc) / formState.productionCalc) * 100;

          setComparisonStatus({
            direct: {
              status: Math.abs(directDiff) > 20 ? 'warning' : 'success',
              difference: directDiff,
            },
            indirect: {
              status: Math.abs(indirectDiff) > 20 ? 'warning' : 'success',
              difference: indirectDiff,
            },
            activity: {
              status: activityDiff < -20 || activityDiff > 50 ? 'warning' : 'success',
              difference: activityDiff,
            },
          });
        }
      } catch (err) {
        console.error('Error fetching default emissions:', err);
      }
    };

    if (formState.CNNumber) {
      fetchAndCompareEmissions();
    }
  }, [
    formState.CNNumber,
    formState.calculatedEmissionsDirectPerTonne,
    formState.calculatedEmissionsIndirectPerTonne,
  ]);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitForm(event, comparisonStatus); // Pass the comparison status up
  };

  // Preserve your existing categories and sourceStreamContent logic
  const categories: Record<string, { name: string; items: IEmission[] }> = {
    CBAM_PRFG: { name: 'Fuels & Gases', items: [] },
    CBAM_PRRP: { name: 'Precursors', items: [] },
    CBAM_PROI: { name: 'Other Inputs', items: [] },
  };

  formState.directEmissions.forEach((emission: IEmission) => {
    if (categories[emission.category]) {
      categories[emission.category].items.push(emission);
    }
  });

  const sourceStreamContent = Object.entries(categories)
    .map(([key, category]) => {
      if (category.items.length > 0) {
        return (
          <AccordionItem key={key} title={`${category.name} (${category.items.length})`}>
            {category.items.map((item) => (
              <p key={item.emission_id} className="text-gray">
                {`${item.emission_name} (${item.activity}${item.unit})`}
              </p>
            ))}
          </AccordionItem>
        );
      }
      return null;
    })
    .filter(Boolean);

  const ValidationHints = ({
    comparisonStatus,
  }: {
    comparisonStatus: {
      direct: { status: string; difference: number };
      indirect: { status: string; difference: number };
      activity: { status: string; difference: number };
    };
  }) => {
    // Only show if there are any warnings
    if (
      comparisonStatus.direct.status !== 'warning' &&
      comparisonStatus.indirect.status !== 'warning' &&
      comparisonStatus.activity.status !== 'warning'
    ) {
      return null;
    }

    return (
      <div className="validation-hints mt-4">
        {/* Emissions too high */}
        {(comparisonStatus.direct.status === 'warning' &&
          comparisonStatus.direct.difference > 20) ||
        (comparisonStatus.indirect.status === 'warning' &&
          comparisonStatus.indirect.difference > 20) ? (
          <div className="hint-section">
            <h5>{t('survey.submission.validation.highEmissionTitle')}</h5>
            <ul>
              <li>{t('survey.submission.validation.highEmission1')}</li>
              <li>{t('survey.submission.validation.highEmission2')}</li>
              <li>{t('survey.submission.validation.highEmission3')}</li>
            </ul>
          </div>
        ) : null}

        {/* Emissions too low */}
        {(comparisonStatus.direct.status === 'warning' &&
          comparisonStatus.direct.difference < -20) ||
        (comparisonStatus.indirect.status === 'warning' &&
          comparisonStatus.indirect.difference < -20) ? (
          <div className="hint-section">
            <h5>{t('survey.submission.validation.lowEmissionTitle')}</h5>
            <ul>
              <li>{t('survey.submission.validation.lowEmission1')}</li>
              <li>{t('survey.submission.validation.lowEmission1')}</li>
              <li>{t('survey.submission.validation.lowEmission1')}</li>
            </ul>
          </div>
        ) : null}

        {/* Activity vs Production deviation */}
        {comparisonStatus.activity.status === 'warning' && (
          <div className="hint-section">
            <h5>{t('survey.submission.validation.activityLevelTitle')}</h5>
            {comparisonStatus.activity.difference > 50 ? (
              <div className="hint-content">
                <p className="hint-emphasis">
                  {t('survey.submission.validation.activityLevelSubtitle')}
                </p>
                <ul>
                  <li>{t('survey.submission.validation.activityLevel1')}</li>
                  <li>{t('survey.submission.validation.activityLevel2')}</li>
                  <li>{t('survey.submission.validation.activityLevel3')}</li>
                </ul>
              </div>
            ) : comparisonStatus.activity.difference < -20 ? (
              <div className="hint-content">
                <p className="hint-emphasis">
                  {t('survey.submission.validation.activityLevelSubtitle2')}
                </p>
                <ul>
                  <li>{t('survey.submission.validation.activityLevel4')}</li>
                  <li>{t('survey.submission.validation.activityLevel5')}</li>
                  <li>{t('survey.submission.validation.activityLevel6')}</li>
                </ul>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };

  const EmissionsComparison = () => (
    <div className="emissions-comparison">
      <Grid>
        <Column sm={4} md={8} lg={16}>
          {/* Direct Emissions */}
          <div className="comparison-section">
            <div className="comparison-header">
              <h4>{t('survey.submission.validation.directEmissionLabel')}</h4>
              {comparisonStatus.direct.status === 'warning' ? (
                <Tag type="red" renderIcon={WarningFilled}>
                  {t('survey.submission.validation.deviationHint')}
                </Tag>
              ) : (
                <Tag type="green" renderIcon={CheckmarkFilled}>
                  {t('survey.submission.validation.deviationHint2')}
                </Tag>
              )}
            </div>
            <div className="comparison-values">
              <div className="value-item">
                <span className="label">{t('survey.submission.validation.valueLabel')}</span>
                <span className="value">
                  {formState.calculatedEmissionsDirectPerTonne.toFixed(2)} t CO2e/t
                </span>
              </div>
              <div className="value-item">
                <span className="label">{t('survey.submission.validation.defaultValue')}</span>
                <span className="value">{defaultEmissions?.direct.toFixed(2)} t CO2e/t</span>
              </div>
              <div className="value-item">
                <span className="label">
                  {t('survey.submission.validation.emissionDifference')}
                </span>
                <span
                  className={`value ${
                    comparisonStatus.direct.status === 'warning' ? 'warning' : 'success'
                  }`}
                >
                  {comparisonStatus.direct.difference.toFixed(1)}%
                </span>
              </div>
            </div>
          </div>

          {/* Indirect Emissions */}
          <div className="comparison-section mt-4">
            <div className="comparison-header">
              <h4>{t('survey.submission.validation.indirectEmissionLabel')}</h4>
              {comparisonStatus.indirect.status === 'warning' ? (
                <Tag type="red" renderIcon={WarningFilled}>
                  {t('survey.submission.validation.deviationHint')}
                </Tag>
              ) : (
                <Tag type="green" renderIcon={CheckmarkFilled}>
                  {t('survey.submission.validation.deviationHint2')}
                </Tag>
              )}
            </div>
            <div className="comparison-values">
              <div className="value-item">
                <span className="label">{t('survey.submission.validation.valueLabel')}</span>
                <span className="value">
                  {formState.calculatedEmissionsIndirectPerTonne.toFixed(2)} t CO2e/t
                </span>
              </div>
              <div className="value-item">
                <span className="label">{t('survey.submission.validation.defaultValue')}</span>
                <span className="value">{defaultEmissions?.indirect.toFixed(2)} t CO2e/t</span>
              </div>
              <div className="value-item">
                <span className="label">
                  {t('survey.submission.validation.emissionDifference')}
                </span>
                <span
                  className={`value ${
                    comparisonStatus.indirect.status === 'warning' ? 'warning' : 'success'
                  }`}
                >
                  {comparisonStatus.indirect.difference.toFixed(1)}%
                </span>
              </div>
            </div>
          </div>

          {/* Activity vs Production */}
          <div className="comparison-section mt-4">
            <div className="comparison-header">
              <h4>{t('survey.submission.validation.activityLevelTitle')}</h4>
              {comparisonStatus.activity.status === 'warning' ? (
                <Tag type="red" renderIcon={WarningFilled}>
                  {t('survey.submission.validation.deviationHint')}
                </Tag>
              ) : (
                <Tag type="green" renderIcon={CheckmarkFilled}>
                  {t('survey.submission.validation.deviationHint2')}
                </Tag>
              )}
            </div>
            <div className="comparison-values">
              <div className="value-item">
                <span className="label">
                  {t('survey.submission.validation.totalActivityLabel')}
                </span>
                <span className="value">
                  {formState.directEmissions
                    .reduce(
                      (sum: number, emission: IEmission) => sum + Number(emission.activity),
                      0,
                    )
                    .toFixed(2)}{' '}
                  t
                </span>
              </div>
              <div className="value-item">
                <span className="label">
                  {t('survey.submission.validation.productionTotalLabel')}
                </span>
                <span className="value">{formState.productionCalc} t</span>
              </div>
              <div className="value-item">
                <span className="label">
                  {t('survey.submission.validation.emissionDifference')}
                </span>
                <span
                  className={`value ${
                    comparisonStatus.activity.status === 'warning' ? 'warning' : 'success'
                  }`}
                >
                  {comparisonStatus.activity.difference.toFixed(1)}%
                </span>
              </div>
            </div>
          </div>
          <h4>{t('survey.submission.validation.checksLabel')}</h4>
          <ValidationHints comparisonStatus={comparisonStatus} />
        </Column>
      </Grid>
    </div>
  );

  return (
    <Form onSubmit={handleFormSubmit} id="step9" className="form-step">
      <h3 className="form-step-title">{t('survey.step9.name')}</h3>

      <InlineNotification
        kind="info"
        hideCloseButton
        title={t('survey.step9.infoTitle')}
        subtitle={t('survey.step9.infoSubtitle')}
      />

      <Tabs>
        <TabList aria-label="Survey Summary">
          <Tab>{t('survey.submission.navigation.overviewTab')}</Tab>
          <Tab>{t('survey.submission.navigation.emissionsTab')}</Tab>
          <Tab>{t('survey.submission.navigation.installationTab')}</Tab>
          <Tab>{t('survey.submission.navigation.additionalTab')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <div className="summary-header">
              <Grid>
                <Column sm={4} md={8} lg={16}>
                  <div className="details-section">
                    <div className="details-content">
                      <div className="details-item">
                        <span className="label">{t('common.parameterTable.CNNumber')}</span>
                        <span className="value">{formState.CNNumber}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('common.parameterTable.CNName')}</span>
                        <span className="value">{formState.CNName}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('survey.step3.name')}</span>
                        <span className="value">{formState.AGC}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">
                          {t('survey.step9.reportingPeriod.reportingPeriodTitle')}
                        </span>
                        <span className="value">
                          {formState.start} - {formState.end}
                        </span>
                      </div>
                    </div>
                  </div>
                </Column>
              </Grid>
            </div>
            <h3>{t('survey.submission.prevalidation.prevalidationTitle')}</h3>
            <EmissionsComparison />
          </TabPanel>
          <TabPanel>
            <Grid>
              <Column sm={4} md={8} lg={16}>
                {/* Direct Emissions Section */}
                <div className="details-section">
                  <div className="comparison-header">
                    <h4>{t('survey.submission.prevalidation.directEmissionDetails')}</h4>
                  </div>
                  <div className="details-content">
                    {Object.entries(categories).map(([key, category]) => {
                      if (category.items.length > 0) {
                        return (
                          <div key={key} className="emissions-category">
                            <h5 className="category-title">{category.name}</h5>
                            {category.items.map((item) => (
                              <div key={item.emission_id} className="emission-item">
                                <div className="emission-name">
                                  <span className="label">
                                    {t('survey.submission.prevalidation.directEmissionSource')}
                                  </span>
                                  <span className="value">{item.emission_name}</span>
                                </div>
                                <div className="emission-details">
                                  <div className="detail-item">
                                    <span className="label">
                                      {t('survey.submission.prevalidation.directActivityData')}
                                    </span>
                                    <span className="value">{`${item.activity} ${item.unit}`}</span>
                                  </div>
                                  <div className="detail-item">
                                    <span className="label">
                                      {t('survey.submission.prevalidation.directFactor')}
                                    </span>
                                    <span className="value">{item.direct_multiplier}</span>
                                  </div>
                                  <div className="detail-item">
                                    <span className="label">
                                      {t('survey.submission.prevalidation.indirectFactor')}
                                    </span>
                                    <span className="value">{item.indirect_multiplier}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>

                {/* Indirect Emissions Section */}
                <div className="details-section mt-4">
                  <div className="comparison-header"></div>
                  {/* Indirect Emissions Section */}
                  <PowerConsumptionComparison indirectEmission={formState.indirectEmission} />
                </div>
              </Column>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid>
              <Column lg={16} md={8} sm={4}>
                {/* Main Installation Info */}
                <div className="details-section installation-header">
                  <h3 className="installation-name">{formState.installationName}</h3>
                  <div className="economic-activity">
                    <span className="label">{t('survey.step1.economicActivity.labelText')}</span>
                    <span className="value">{formState.economicActivity}</span>
                  </div>
                </div>
                {formState.calculationMethod === 'calculated' && (
                  <div className="details-section production-section">
                    <div className="details-header">
                      <h4>{t('survey.submission.installationDetails.productionData')}</h4>
                    </div>
                    <div className="details-content">
                      <div className="production-grid">
                        <div className="details-item">
                          <span className="label">
                            {t('survey.step9.activityLevel.totalProduction')}
                          </span>
                          <span className="value highlight">{formState.productionTotal} t</span>
                        </div>
                        <div className="details-item">
                          <span className="label">
                            {t('survey.step9.activityLevel.scrapProduction')}
                          </span>
                          <span className="value highlight">{formState.productionScrap} t</span>
                        </div>
                        <div className="details-item">
                          <span className="label">
                            {t('survey.step9.activityLevel.totalProductionCalculated')}
                          </span>
                          <span className="value highlight">{formState.productionCalc} t</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Two Column Layout for Address and Contact */}
                <div className="details-grid">
                  {/* Installation Address */}
                  <div className="details-section">
                    <div className="details-header">
                      <h4>{t('survey.step9.installationData.installationDataTitle')}</h4>
                    </div>
                    <div className="details-content">
                      <div className="details-item">
                        <span className="label">{t('survey.step9.installationData.address')}</span>
                        <span className="value">
                          {formState.street} {formState.streetNumber}
                        </span>
                      </div>
                      {formState.POBox && (
                        <div className="details-item">
                          <span className="label">{t('survey.step1.POBox.labelText')}</span>
                          <span className="value">{formState.POBox}</span>
                        </div>
                      )}
                      {formState.additionalLine && (
                        <div className="details-item">
                          <span className="label">
                            {t('survey.step1.additionalLine.labelText')}
                          </span>
                          <span className="value">{formState.additionalLine}</span>
                        </div>
                      )}
                      <div className="details-item">
                        <span className="label">{t('survey.step1.city.labelText')}</span>
                        <span className="value">
                          {formState.postCode} {formState.city}
                        </span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('survey.step1.country.labelText')}</span>
                        <span className="value">{formState.country.country_name}</span>
                      </div>
                    </div>
                  </div>

                  {/* Contact Person */}
                  <div className="details-section">
                    <div className="details-header">
                      <h4>Contact Person</h4>
                    </div>
                    <div className="details-content">
                      <div className="details-item">
                        <span className="label">{t('survey.step1.representative.labelText')}</span>
                        <span className="value">{formState.representative || 'Not provided'}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('survey.step1.email.labelText')}</span>
                        <span className="value">{formState.email || 'Not provided'}</span>
                      </div>
                      <div className="details-item">
                        <span className="label">{t('survey.step1.telephone.labelText')}</span>
                        <span className="value">{formState.telephone || 'Not provided'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Column>
            </Grid>
          </TabPanel>
          <TabPanel>
            {/* Additional Information Content */}
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <div className="details-section">
                  <h4>{t('survey.step9.remarks.remarksTitle')}</h4>
                  <TextArea
                    id="remarks"
                    labelText=""
                    placeholder={t('survey.step9.remarks.remarksText')}
                    value={formState.remarks}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      handleChange({ target: { name: 'remarks', value: e.target.value } });
                    }}
                  />
                </div>
                <div className="details-section">
                  <FileUploader
                    labelTitle="Upload files"
                    labelDescription="Max file size is 10 MB. Supported file types are .pdf, .xls, .xlsx"
                    buttonLabel="Add file"
                    buttonKind="primary"
                    size="md"
                    filenameStatus="edit"
                    multiple={false}
                    accept={['.pdf', '.xls', '.xlsx']}
                    disabled={false}
                    iconDescription="Delete file"
                    onChange={onFilesUpload}
                  />
                </div>
              </Column>
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Form buttons */}
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" type="submit">
                {t('common.buttons.submit')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};

export default FormStep9;
